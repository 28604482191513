<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Asetukset</div>
    <v-card class="mt-2">
      <v-card-title>Stripe</v-card-title>

      <v-card-text>
        <v-container>
          <h2>
            Jokin saattoi meni vikaan. Yhdistetään automaattisesti Stripe-profiiliin takaisin.
          </h2>
          <h2>Odota hetki...</h2>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    setTimeout(() => {
      this.createStripeAccount();
    }, 2000);
  },

  methods: {
    ...mapActions("stripe", ["createStripeAccount"]),
  },
};
</script>

<style></style>
